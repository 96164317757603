import axios, { CancelTokenSource } from 'axios';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../types/redux/store';

interface MakeJsonCallProps {
  [key: string]: any;
  url: string;
}
interface MakeJsonCallAction {
  startAction: any;
  successAction: any;
  failureAction: any;
  props: MakeJsonCallProps;
  cancelSource: CancelTokenSource;
}

/*eslint-disable */
export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? '../../'
    : // 'https://dev1.ucits.risksystem.com/';
      // 'https://dev2tom.risksystem.com/';
      // 'https://dev2david.risksystem.com/';
      // 'https://dev2darrach.risksystem.com/';
      'https://dev2lavy.risksystem.com/';
// 'https://dev2han.risksystem.com/';
// 'https://dev2jkeogh.risksystem.com/';
// 'https://dev2sean.risksystem.com/';
// 'https://dev2felix.risksystem.com/';
// 'https://dev2lavy.risksystem.com/';
// 'https://dev2lavy.risksystem.com/';
// 'https://dev2zoe.risksystem.com/';
// 'http://dev2sean/';
// 'http://dev2felix/';
// 'http://dev2david/';
// 'http://10.2.26.3:8080/';

/*eslint-enable */

const client = () => {
  return axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
  });
};

// The general data fetch function. This is used for all data fetches, allowing us to cache data in redux and prevent unnecessary calls.

// This issue with this hook is if we are on a raidr route we need to use the raidrClient, but if we are on a non-raidr route we need to use the client.
export const makeGeneralDataFetch =
  ({
    startAction,
    successAction,
    failureAction,
    cancelSource,
    props,
  }: MakeJsonCallAction): ThunkAction<void, AppState, null, any> =>
  (dispatch) => {
    dispatch(startAction(props));
    const requestClient = client();
    // this is a quick hack to use the local config if we're not in production
    if (!props.returnLocalConfig) {
      // if we have a formData object, we're doing a post request
      if (props.formData) {
        requestClient
          .post(props.url, props.formData, {
            cancelToken: cancelSource.token,
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .then((innerResponse: any) => {
            const { data } = innerResponse;
            setTimeout(() => {
              dispatch(successAction({ ...props, data }));
            }, 1);
          })
          .catch((error: any) => {
            dispatch(
              failureAction({
                ...props,
                errorMessage: error,
              }),
            );
          });
        // otherwise we're doing a get request
      } else {
        const baseURL = requestClient.defaults.baseURL;
        // Combine base URL and endpoint to form the full URL
        const fullURL = `${baseURL}${props.url}`;

        // Log the full URL for the POST request
        requestClient
          .get(props.url, { cancelToken: cancelSource.token })
          .then((innerResponse: any) => {
            const { data } = innerResponse;
            setTimeout(() => {
              dispatch(successAction({ ...props, data }));
            }, 1);
          })
          .catch((error: any) => {
            dispatch(
              failureAction({
                ...props,
                errorMessage: error,
              }),
            );
          });
      }
    } else {
      try {
        import(`../client_configurations/config/${props.returnLocalConfig}`)
          .then((module) => {
            return module.default;
          })
          .then((data) => {
            dispatch(successAction({ ...props, data }));
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

export default client;
